import React from 'react';
import logo from './logo.svg';
import './App.css';
import Policy from './pages/Policy'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';

function App() {
  return (
    <Routes>
      <Route path='/'  element={<Home/>}/>
      <Route path='/privacy' element={<Policy/>}/>
    </Routes>
    
  );
}

export default App;
