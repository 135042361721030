import React from 'react'
import { services } from '../constant'

const Services = () => {
  return (
    <section id='services' className='flex flex-col gap-4 px-[2%] pt-20'>
      <h2 className='py-2 text-4xl font-bold'>Nos Services</h2>
      <div className='w-full h-[80vh] grid grid-cols-1 md:grid-cols-3 gap-4'>
        {services.map((service:any,idx)=>
        <div  key={idx} className='bg-cover rounded-lg before:w-full before:bg-[#00000050]' style={{backgroundImage:`url(${service.imgPath})`}}>
          <div className='w-full h-full bg-[#00000050] rounded-lg text-white text-center p-6 text-xl font-bold'>{service.content}</div>
        </div>
        )}
      </div>
    </section>
  )
}

export default Services