export const AppleStoreIcon=()=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none">
  <path d="M3.09428 18.5718C3.17715 18.8936 3.35386 19.1834 3.60193 19.4045C3.85001 19.6256 4.15824 19.7678 4.48741 19.8132C4.81658 19.8586 5.15182 19.8051 5.45047 19.6594C5.74912 19.5138 5.9977 19.2825 6.16457 18.9952L6.52457 18.378M11.8594 13.1752H9.54171L12.0171 8.90662L15.3394 3.17748C15.5514 2.79393 15.6046 2.34257 15.4877 1.92024C15.3707 1.49791 15.0929 1.13824 14.7137 0.918407C14.3346 0.698571 13.8845 0.636079 13.4598 0.744336C13.0352 0.852593 12.6699 1.12298 12.4423 1.49748L12.0171 2.22948L11.5937 1.49748C11.4859 1.30244 11.3403 1.13088 11.1653 0.992837C10.9904 0.854797 10.7897 0.753057 10.5749 0.693576C10.3601 0.634096 10.1357 0.61807 9.91464 0.64644C9.6936 0.67481 9.48046 0.747003 9.28768 0.858792C9.0949 0.97058 8.92637 1.11972 8.79196 1.29746C8.65755 1.47521 8.55996 1.67799 8.50491 1.89393C8.44986 2.10987 8.43845 2.33462 8.47136 2.55502C8.50428 2.77542 8.58084 2.98704 8.69657 3.17748L10.0817 5.56719L5.66914 13.1752H2.74628C2.30208 13.1752 1.87608 13.3516 1.56198 13.6657C1.24788 13.9798 1.07143 14.4059 1.07143 14.85C1.07143 15.2942 1.24788 15.7203 1.56198 16.0344C1.87608 16.3484 2.30208 16.5249 2.74628 16.5249H13.2857" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M15.324 7.83984L18.3669 13.1764H21.0343C21.4785 13.1764 21.9045 13.3529 22.2186 13.667C22.5327 13.9811 22.7091 14.4071 22.7091 14.8513C22.7091 15.2955 22.5327 15.7215 22.2186 16.0356C21.9045 16.3497 21.4785 16.5261 21.0343 16.5261H20.3091L20.7686 17.3164C20.8843 17.5069 20.9609 17.7185 20.9938 17.9389C21.0267 18.1593 21.0153 18.384 20.9602 18.6C20.9052 18.8159 20.8076 19.0187 20.6732 19.1964C20.5388 19.3742 20.3702 19.5233 20.1775 19.6351C19.9847 19.7469 19.7715 19.8191 19.5505 19.8475C19.3295 19.8758 19.105 19.8598 18.8902 19.8003C18.6755 19.7408 18.4747 19.6391 18.2998 19.5011C18.1249 19.363 17.9792 19.1915 17.8714 18.9964L13.6097 11.6987" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);

export const PlayStoreIcon=()=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M15.8228 15.8233L1.93714 1.9359M15.8228 7.70275L1.93714 21.5885M1.47256 20.5393V2.98504C1.47256 1.83304 2.82685 1.10275 3.93085 1.66333L21.2057 10.4405C22.3406 11.0165 22.3406 12.5096 21.2057 13.0856L3.93085 21.8628C2.82685 22.4233 1.47256 21.693 1.47256 20.5393Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)

export const PhDrowp=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M16.3125 4.97635C15.1545 3.63895 13.8511 2.43465 12.4266 1.38573C12.3005 1.29739 12.1502 1.25 11.9963 1.25C11.8423 1.25 11.692 1.29739 11.5659 1.38573C10.144 2.43509 8.84317 3.63937 7.6875 4.97635C5.11031 7.93604 3.75 11.056 3.75 13.9998C3.75 16.1878 4.61919 18.2862 6.16637 19.8334C7.71354 21.3806 9.81196 22.2498 12 22.2498C14.188 22.2498 16.2865 21.3806 17.8336 19.8334C19.3808 18.2862 20.25 16.1878 20.25 13.9998C20.25 11.056 18.8897 7.93604 16.3125 4.97635ZM12 20.7498C10.2104 20.7478 8.49466 20.036 7.22922 18.7706C5.96378 17.5051 5.25199 15.7894 5.25 13.9998C5.25 8.63448 10.4503 4.15604 12 2.93729C13.5497 4.15604 18.75 8.6326 18.75 13.9998C18.748 15.7894 18.0362 17.5051 16.7708 18.7706C15.5053 20.036 13.7896 20.7478 12 20.7498ZM17.2397 14.8754C17.0452 15.9617 16.5226 16.9623 15.7422 17.7426C14.9618 18.5228 13.961 19.0452 12.8747 19.2395C12.8334 19.2461 12.7918 19.2495 12.75 19.2498C12.5619 19.2497 12.3806 19.179 12.2422 19.0516C12.1038 18.9241 12.0183 18.7494 12.0028 18.5619C11.9872 18.3744 12.0426 18.1879 12.1581 18.0394C12.2736 17.8909 12.4407 17.7912 12.6262 17.7601C14.1797 17.4985 15.4978 16.1804 15.7612 14.6242C15.7946 14.428 15.9045 14.2531 16.0667 14.1379C16.229 14.0228 16.4304 13.9768 16.6266 14.0101C16.8227 14.0434 16.9976 14.1533 17.1128 14.3156C17.228 14.4779 17.2739 14.6792 17.2406 14.8754H17.2397Z" fill="#333333"/>
</svg>
)