import React from 'react'
import { dowloadLink } from '../constant'
import downloadivce from '../assets/images/iPhone 14 Pro Mockup.png'

const DowloadApp = () => {
  return (
    <section id='application' className='h-[80vh] w-full  rounded-xl pt-20'>
      <section className='h-full rounded-xl  flex justify-between px-10 pt-10'>
      <div className='w-full flex flex-col   gap-14'>
        <p className='font-bold text-4xl'>Passer vos prochaines commandes!</p>
        <p className=''>Téléchargez l'application Adnoc voyager et profitez de nos meilleures offres</p>

        <div className=' flex gap-4 '>
          {dowloadLink.map((link,idx)=>
          <a key={idx} href={link.dowloadLink} className='bg-primary p-4 flex items-center text-white gap-2 rounded-lg'>
            <span>{<link.icon/>}</span>
            <span className='flex flex-col'>
              <span>{link.label}</span>
              <span>{link.device}</span>
            </span>
          </a>
          )}
        </div>
      </div>
      <div className='w-full hidden md:flex justify-end '><img src={downloadivce} alt=''/></div>
      </section>
    </section>
  )
}

export default DowloadApp