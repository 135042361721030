import React from "react";
import { Navbar } from "../components";
import logo from "../assets/icons/Group 8.svg";
import { Link } from "react-router-dom";
import { Foot } from "../sections";

const Policy = () => {
  return (
    <>
      <section className="bg-gray-100">
        <nav className="bg-white p-4">
          <Link to={"/"}>
            <img className="h-10" src={logo} alt="logo" />
          </Link>
        </nav>
        <h1 className="text-4xl font-bold text-center py-4 ">
          CONDITIONS GÉNÉRALES D'UTILISATION
        </h1>
        <section className="md:p-4 space-y-4 flex flex-col md:px-[20%] py-8 ">
          <section className="bg-white p-2 md:p-8">
            <p>Dernière modification: 03 janvier 2024</p>
            <section>
              <ol className="list-decimal list-inside space-y-4">
                <section className="space-y-4 py-4">
                  <li className="text-2xl font-bold">
                    Inscription et création de compte
                  </li>
                  <section className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="underline font-bold text-xl">
                        Conditions d'inscription
                      </h3>
                      <p>
                        Les utilisateurs de la plateforme doivent être des
                        personnes physiques majeures (âgées de 18 ans ou plus).
                        L’inscription de toute personne mineure est strictement
                        interdite sur la plateforme.
                      </p>
                    </div>

                    <div className="space-y-2">
                      <h3 className="underline font-bold text-xl">
                        Création de compte
                      </h3>
                      <p>
                        Les membres inscrits et ayant complété leur profil sur
                        la plateforme peuvent publier des annonces de trajet de
                        leur voyage et consulter des annonces puis entrer en
                        interaction pour réserver des places. Nul ne pourra
                        publier une annonce de trajet ou réserver une place sans
                        avoir créé un compte sur la plateforme (complété son
                        profil après inscription).
                      </p>
                    </div>

                    <div className="space-y-2">
                      <p>Pour créer votre compte, vous devez :</p>
                      <div className="p-4 space-y-3">
                        <p>
                          Dans un premier temps en saisissant les informations
                          demandées sur l’interface d’inscription de
                          l’application, ensuite vous connectez à votre compte
                          et compléter l’intégralité des champs obligatoires et
                          joindre les justificatifs des pièces nécessaires à la
                          création de votre profil dans un second temps.
                        </p>
                        <p>
                          Pour vous inscrire sur la Plateforme, vous devez
                          prendre connaissance des Conditions Générales (CGU)
                          autrement lire et accepter les CGU.
                        </p>
                        <p>
                          A la création de votre compte, vous êtes tenu de
                          renseigner vos informations personnelles exactes et
                          conformes à la réalité et ne pas manquer de les mettre
                          à jour, via le profil de votre compte ou d’informer
                          ADNOC Voyager, afin que celle-ci garantit la pertinence et
                          l’exactitude vos informations personnelles tout au
                          long de votre relation avec lui.
                        </p>
                        <p>
                          Une fois votre compte créé, vous vous engagez à garder
                          secret votre mot de passe et à ne pas le partager avec
                          d’autres personnes. Il est très important de notifier
                          dans les brefs délais à ADNOC, d’une éventuelle perte
                          ou de divulgation de votre mot de passe à un tiers.
                          Dans le cas contraire vous serez considéré comme
                          l’unique responsable face à une utilisation
                          frauduleuse de votre compte. Chaque membre aura droit
                          à un et un seul compte sous son identité et ne tentera
                          de créer d’autres comptes sur la plateforme en se
                          servant de l’identité d’un tiers.
                        </p>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <h3 className="underline font-bold text-xl">
                        Vérification
                      </h3>
                      <div className="space-y-3">
                        <p>
                          Pour des raisons de transparence, d’amélioration de la
                          confiance, de prévention ou détection d’éventuelles
                          fraudes, ADNOC Voyager se réserve le droit, de vérifier
                          certaines vos informations que vous fournissez sur
                          votre profil afin de valider ce dernier, notamment
                          lorsque vous renseignez votre numéro de téléphone ou
                          fournissez une pièce d’identité ou encore les pièces
                          relatives à votre véhicule ou à la moto (permis de
                          conduire, carte grise, etc.) et peut demander aux
                          membres de fournir d’autres informations avec
                          lesquelles ils prévoient voyager.
                        </p>
                        <p>
                          ADNOC{" "}
                          <b className="text-secondary">
                            ne garantit ni la véracité, ni la fiabilité, ni la
                            validité de l’information ayant fait l’objet de la
                            procédure de vérification.
                          </b>
                        </p>
                      </div>
                    </div>
                  </section>
                </section>

                <section className="space-y-4 py-4">
                  <li className="text-2xl font-bold">Usage des services</li>
                  <section className="space-y-4">
                    <div className="space-y-3">
                      <h3 className="underline font-bold text-xl">
                        Utilisateurs de la plateforme & Engagement
                      </h3>
                      <div className="space-y-2">
                        <p>
                          Vous reconnaissez être seul responsable du respect de
                          l’ensemble des lois, règlements et obligations
                          applicables à votre utilisation de la plateforme. Par
                          ailleurs, en utilisant la plateforme et lors des
                          trajets, vous vous engagez à :
                        </p>
                        <ul className="list-disc space-y-2 list-inside">
                          <li>
                            Ne pas utiliser la plateforme à des fins
                            professionnelles, commerciales ou lucratives
                          </li>
                          <li>
                            Ne pas accepter ou effectuer un paiement en dehors
                            de la plateforme
                          </li>
                          <li>
                            Respecter le présent CGU et la politique de
                            confidentialité.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <p>
                        L’autorisation de publication d’une annonce de trajet de
                        voyage est sous le contrôle des conditions suivantes :
                      </p>
                    </div>
                  </section>
                </section>

                <section className="space-y-4 py-4">
                  <li className="text-2xl font-bold">ADNOC Voyager</li>
                  <div>
                    <p>
                      Plateforme qui permet aux utilisateurs de créer leur
                      compte,de .
                    </p>
                  </div>
                </section>

                <section className="space-y-4 py-4">
                  <li className="text-2xl font-bold">
                    Disponibilité & fonctionnement de la Plateforme
                  </li>
                  <p>
                    ADNOC Voyager essayera de faire de son mieux pour vous donner accès
                    à la plateforme 7 jours sur 7 et 24 heures sur 24. Par
                    ailleurs, toutes opérations de maintenance, de mises à jour,
                    de pannes d'électricité ou de dysfonctionnements liés aux
                    réseaux ou indépendants à notre contrôle pourront toutefois
                    limiter l’accès à la plateforme de façon temporaire
                  </p>
                </section>

                <section className="space-y-4 py-4">
                  <li className="text-2xl font-bold">
                    Gestion des données personnelles
                  </li>
                  <p>
                    L’inscription et l’utilisation de la plateforme ADNOC Voyager est
                    synonyme de collecte et de traitement de quelques-unes de
                    vos données personnelles. A cet effet ADNOC Voyager traitera vos
                    données personnelles conformément à la loi applicable et aux
                    stipulations de la Politique de Confidentialité.
                  </p>
                  <p>
                    La protection de vos données passe par la mise en place des
                    mesures de sécurité techniques, physiques et administratives
                    pour protéger vos données personnelles contre tout accès non
                    autorisé, utilisation, divulgation, altération ou
                    destruction.
                  </p>
                  <p>
                    Nous conserverons vos données personnelles aussi longtemps
                    que nécessaire pour atteindre les finalités pour lesquelles
                    elles ont été collectées, à moins que la loi ne nous oblige
                    à les conserver plus longtemps.
                  </p>
                </section>
              </ol>
            </section>
          </section>
        </section>
      </section>
      <Foot />
    </>
  );
};

export default Policy;
