import React from 'react'
import { Services, DowloadApp, Foot } from '../sections'
import Header from '../sections/Header'

const Home = () => {
  return (
      <main className='w-full flex flex-col  '>
      <Header/>
      <Services/>
      <DowloadApp/>
      <Foot/>
     </main>
  )
}

export default Home