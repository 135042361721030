import React from 'react'
import logo from '../assets/icons/Group 8.svg'
import { contact } from '../constant'
import { Link } from 'react-router-dom'

const Foot = () => {
  return (
    <footer id='contact' className='bg-black flex flex-col gap-2  text-white p-[2%] '>
      <section className='w-full flex items-center '>
        <div className='w-full flex flex-col gap-4'>
          <Link to={'/'}><img src={logo} alt='logo'/></Link>
          <p className='text-sm'>Etabli à Ouagadougou, 01 BP 5584 Ouagadougou 01, secteur 30 de Ouagadougou, le quartier Nagrin juste à 100 mètre après la station Shell.</p>
        </div>
        <div className='w-full flex flex-col items-end gap-2 '>
          {contact.map((cont:any,idx)=><a className='' key={idx} href={cont}>{cont}</a>)}
        </div>
      </section>
      <hr className=''/>
      <div className='flex flex-col md:flex-row justify-between'>
      <span className='py-2 text-sm'>&copy;Copyright BSC Distribution, tout droit réservé</span>
      <Link to={'/privacy'} className='text-primary underline'>Condition d'utilisation</Link>
      </div>
    </footer>
  )
}

export default Foot