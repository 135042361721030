import {  AppleStoreIcon, PlayStoreIcon } from '../assets/icons'
import service1 from '../assets/images/service1.jpeg'
import service2 from '../assets/images/service2.jpeg'
import service3 from '../assets/images/service3.jpeg'
export const navLists: {label:string,path:string}[]=[
 
    {
        label:`Télécharger l'application`,
        path:'#application'
    },
    {
        label:'Devenir partenaire',
        path:'#contact'
    },
    
]

export const services=[
    {
        content:`Lubrifiant pour les moteurs de 2 à 4 roues`,
        imgPath:service1,
        alt:'sercece1'
    },
    {
        content:`Lubrifiant pour les véhicules poids lourd et poids léger`,
        imgPath:service2,
        alt:'sercece2'
    },
    {
        content:`Des machines de transformations`,
        imgPath:service3,
        alt:'sercece3'
    },
]

export const dowloadLink:{label:string,device:string,icon:any,dowloadLink:string}[]=[{
    label:`Télécharger l'application`,
    device:`Sur l'App Store`,
    icon:AppleStoreIcon,
    dowloadLink:'https://apps.apple.com/us/app/bsc-distribution/id6475569718'
},
{
    label:`Télécharger l'application`,
    device:`Sur le Play Store`,
    icon:PlayStoreIcon,
    dowloadLink:'https://play.google.com/store/apps/details?id=bf.adnoc.app'
},
]

export const contact=[
    '+ 226 02 70 85 85',
    '+ 226 61 31 36 36',
    '+ 226 25 37 66 51'
]