import React from 'react'
import banner from '../assets/images/banner1.jpeg'
import ellipse from '../assets/images/ellipse.png'
import frame from '../assets/images/frame.png'
import { Navbar } from '../components'
import cta from '../assets/images/CTA.png'
import { PhDrowp } from '../assets/icons'
import blurBg from '../assets/images/Ellipse 5(2).jpg'
const Header = () => {
  return (
    <section className='w-full h-[80vh] relative'>
      <Navbar />
      <section className='w-full h-full flex'>
        <section className='w-full bg-cover bg-no-repeat flex items-center justify-center' style={{backgroundImage:`url(${ellipse})`}}>
          <div className='space-y-4 px-[2%]'>
            <h1 className='text-3xl font-bold'>Donner un nouveau souffle <br/> à votre moteur</h1>
            <p className='text-sm'>Créée en mars 2023, BSC distribution est une filiale du Groupe BSC International. Cette filiale est spécialisée dans la commercialisation des lubrifiants. BSC Distribution est dans la distribution des huiles lubrifiantes de marque ADNOC au Burkina Faso et dans
               la sous-région ouest africaine.</p>
            <div className='space-x-4'>
              <button className='bg-primary p-4 rounded-md text-white'>Passer une commande</button>
              <button>Devenir partenaire</button>
            </div>
          </div>
        </section>
        <section className='w-full hidden relative md:flex justify-center'>
          <div className='absolute -bottom-4'><img src={frame} alt='hero banner'/></div>
        </section>
      </section>
    </section>
  );
};

export default Header;
