import React from 'react'
import logo from '../assets/icons/Group 8.svg'
import { navLists } from '../constant'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
    <nav className='hidden absolute z-20  w-full md:flex justify-between items-center p-4'>
        <div ><img className='h-10' src={logo} alt='logo'/></div>
        <div className='flex gap-10 items-center'>
            {navLists.map((list,idx)=><a key={idx} className='text-lg' href={list.path}>{list.label}</a>)}
        </div>
    </nav>
     <nav className="bg-white p-4 md:hidden">
     <Link to={"/"}>
       <img className="h-10" src={logo} alt="logo" />
     </Link>
   </nav>
   </>
  )
}

export default Navbar